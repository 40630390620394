
import {Component, Vue} from 'vue-property-decorator';
import GoodsCard from '../$components/gift-card/gift-card.vue';
import {GoodsProxy} from '@/combo-floor/renovation/services/goods';

@Component({
  name: 'placeholder',
  components: {
    GoodsCard,
  }
})
export default class Placeholder extends Vue {
  giftData: GoodsProxy[] = new Array(2).fill(0).map(() => new GoodsProxy(null));
}
