import {getGoodsCategories} from '../api';
import {generateKey} from './key';
import {getApp} from '@/combo-floor/renovation/services/app';

export class GoodsCategory {
  id = 0;
  name = '';
  index = 0;
  parentId = 0;

  constructor(data: any) {
    this.id = data.cat_id;
    this.name = data.name;
    this.index = data.cat_class;
    this.parentId = data.parent_id;
  }
}

export class GoodsProxy {
  static unit = '';
  static unitSuffix = false;

  static init() {
    if (this.unit) return;
    const monetary_unit = getApp().$store.getters.shopInfo.monetary_unit;
    this.unit = monetary_unit || '￥';
    this.unitSuffix = !!monetary_unit;
  }

  key = generateKey();
  id = 0;
  cover = '';
  name = '';
  unit = GoodsProxy.unit;
  unitSuffix = GoodsProxy.unitSuffix;
  price = 0;
  priceInt = '';
  priceFloat = '';
  marketPrice = 0;
  marketPriceInt = '';
  marketPriceFloat = '';
  isEmpty = false;

  originalData: any;

  static create(data: any) {
    GoodsProxy.init();
    return new GoodsProxy(data);
  }

  static createEmpty() {
    GoodsProxy.init();
    return new GoodsProxy();
  }

  constructor(data?: any) {
    this.originalData = {};
    // console.log(data, "good");
    if (data) {
      if (typeof data === 'string') {
        data = data.replace(/\\/g, '').replace(/""/g, '')
        data = JSON.parse(data);
      }
      // console.log(data)
      if (!data.goods_id && !data.originalData.goods_id) {
        this.isEmpty = true;
      } else {
        // console.log(111111, data, this.originalData)
        // if (!data.originalData) {
        //   this.originalData = data;
        // }
        this.originalData = data
        data.goods_image = data.thumbnail;
        this.id = data.originalData ? data.originalData.goods_id : data.goods_id;
        this.cover = data.goods_image;
        this.name = data.goods_name ? data.goods_name.replace(/\\/g, '').replace(/"/g, "'") : undefined;
        this.initPrice();
        this.initMarketPrice();
      }
    } else {
      this.isEmpty = true;
    }
  }

  private initPrice() {
    const data = this.originalData;

    if (data.revise_exchange_point) {
      this.unit = '积分';
      this.unitSuffix = true;
      const {
        priceInt,
        priceFloat
      } = splitPrice(data.revise_exchange_point);
      this.price = data.revise_exchange_point;
      this.priceInt = priceInt;
      this.priceFloat = priceFloat;
    } else if (data.revise_price) {
      const {
        priceInt,
        priceFloat
      } = splitPrice(data.revise_price);
      this.price = data.revise_price;
      this.priceInt = priceInt;
      this.priceFloat = priceFloat;

      if (data.monetary_unit) {
        this.unit = data.monetary_unit;
        this.unitSuffix = true;
      }
    }
  }

  private initMarketPrice() {
    const data = this.originalData;
    const {
      priceInt,
      priceFloat
    } = splitPrice(data.mktprice);
    this.marketPrice = data.mktprice;
    this.marketPriceInt = priceInt;
    this.marketPriceFloat = priceFloat;
  }

  toJson() {
    return this.originalData;
  }
}

function splitPrice(price: string | number) {
  price = (price || '').toString();
  const priceArray = price.split('.');
  return parsePrice(priceArray[0], priceArray[1]);
}

function parsePrice(priceInt: string, priceFloat: string) {
  return {
    priceInt: priceInt,
    priceFloat: priceFloat,
  };
}

export class GoodsService {
  categories: GoodsCategory[] = [];
  selectGoods!: (multiple?: boolean, selectedGoods?: GoodsProxy[]) => Promise<GoodsProxy[]>;

  getGoodsCategories(index = 1) {
    return getGoodsCategories(index).then(resp => {
      this.categories = resp.map(c => new GoodsCategory(c));
      return this.categories;
    });
  }
}

export const $goods = new GoodsService();
